import React from "react";
// import { connect } from 'react-redux';

// import { setAlert } from 'api/alerts/requests';

const Alert = () => (
  <div className={`text-white px-6 py-4 border-0 rounded relative mb-4 `} >
    <span className="inline-block align-middle mr-8">
      
    </span>
  </div>
);

// const mapStateToProps = (state) => ({
//   alerts: state.alertApi
// });

export default Alert;